document.addEventListener('DOMContentLoaded', function() {
    iniciarApp();
})


function iniciarApp() {
    crearAviso();
    cerrarAviso();
    cerrar_menu();
    autoScroll();

}

//* Crea aviso de Atención al dar click en navegador! 
function crearAviso() {
    let aviso_mostrado = false;

    const enlaces = document.querySelectorAll('#menu a');

    enlaces.forEach( enlace => {
        enlace.addEventListener('click', function(e) {
            if (!aviso_mostrado){
                const avisoEstafa = ('Aviso importante: Acsein S.A. de C.V. Sofom E.N.R. nunca te pedirá dinero por adelantado para autorizar o entregar sus servicios. Existen grupos fraudulentos haciéndose pasar por Acsein S.A. de C.V. Sofom E.N.R. pidiendo pagos en adelantado para estafar a las personas. NO TE DEJES ESTAFAR. De ser así favor de comunicarse inmediantamente con nosotros al (449 238 4026)');
                alert(avisoEstafa);
                aviso_mostrado = true;
            }
          });
        });
}

/* Hacer que al dar click en "entendido" también fuera como si se diera click en la x para cerrar el aviso de prevenciön */

function cerrarAviso() {
    const check_anuncio = document.getElementById('check_anuncio');
    const check_entendido = document.getElementById('check_entendido');

    check_entendido.addEventListener('click', function() {
        check_anuncio.checked = this.checked;
    })
}



/* Header movil después de tanto scroll*/
/*
function navegacionFija() {
    const barra = document.querySelector('.borderbox');
    const ContenidoPrincipal = document.querySelector('.contenido_promocion');
    const body = document.querySelector('body');

    window.addEventListener('scroll', function() {

        if(ContenidoPrincipal.getBoundingClientRect() .top < 0) {
            barra.classList.add('fijo');
            body.classList.add('body-scroll');
        }
    })
}

function scrollNav() {
    const enlaces = document.querySelectorAll('.menu_navegador li a');

    enlaces.forEach (enlace => {
        enlace.addEventListener('click', function(e) {

            const seccionScroll = e.target.attributes.href.value;
            const seccion = document.querySelector(seccionScroll);
            seccion.scrollIntoView({
                behavior:"smooth"});
            })
        })
    } */



//** Función Cerrar menu en pantallas moviles *//
function cerrar_menu() {
    var links = document.querySelectorAll('nav ul li a');
    var menuCheckbox = document.getElementById('check');

    links.forEach(function(link) {
        link.addEventListener('click', function(event) {
            if (window.innerWidth <= 500) {
                menuCheckbox.checked = false;
            }
        });
    });

};
//** *//

// document.addEventListener("DOMContentLoaded", function() {
//     var enlaces = document.querySelectorAll('ul li a');

//     for (var i = 0; i < enlaces.length; i++) {
//         enlaces[i].addEventListener('click', function() {
//             for(var j = 0; j < enlaces.length; j++) {
//                 enalnces[j].classList.remove('active');
//             }
//             this.classList.add('active');
//         });
//     }
// });

// Función para mover el slider automáticamente
function autoScroll() {
    const slider = document.querySelector('.slider_conteiner'); // Se corrigió el nombre de la clase
    
    const scrollWidth = slider.scrollWidth;
    const visibleWidth = slider.clientWidth;
    const scrollLeft = slider.scrollLeft;

    let targetScroll = scrollLeft + visibleWidth;

    if (targetScroll >= scrollWidth) {
        targetScroll = 0;
    }

    slider.scrollTo({
        left: targetScroll,
        behavior: 'smooth'
    });
};

setInterval(autoScroll, 5000);


// Detecta el evento de scroll

